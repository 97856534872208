exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-design-code-tsx": () => import("./../../../src/pages/design/code.tsx" /* webpackChunkName: "component---src-pages-design-code-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-me-tsx": () => import("./../../../src/pages/me.tsx" /* webpackChunkName: "component---src-pages-me-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-templates-gallery-item-tsx": () => import("./../../../src/templates/GalleryItem.tsx" /* webpackChunkName: "component---src-templates-gallery-item-tsx" */),
  "component---src-templates-note-tsx": () => import("./../../../src/templates/Note.tsx" /* webpackChunkName: "component---src-templates-note-tsx" */),
  "component---src-templates-notes-tsx": () => import("./../../../src/templates/Notes.tsx" /* webpackChunkName: "component---src-templates-notes-tsx" */),
  "component---src-templates-paintings-tsx": () => import("./../../../src/templates/Paintings.tsx" /* webpackChunkName: "component---src-templates-paintings-tsx" */),
  "component---src-templates-photos-tsx": () => import("./../../../src/templates/Photos.tsx" /* webpackChunkName: "component---src-templates-photos-tsx" */)
}

