const transitionDelay = 200

// https://markoskon.com/modals-in-gatsby/
const pagePaintings = '/paintings'
const pagePhotos = '/photos'
const checkPage = (page, loadPage) => {
  const pageDataName = `pageData${page}`
  const setPageDataName = `setPageData${page}`
  if (window[pageDataName] === undefined) {
    loadPage(page).then((result) => {
      window[pageDataName] = result
      // If we load the modal page directly we need to
      // re-render by changing the state. Otherwise
      // the initial result is null for some reason.
      if (window[setPageDataName]) window[setPageDataName]()
    })
  }
}
export const onPrefetchPathname = ({ loadPage }) => {
  if (window.innerWidth > 750) {
    checkPage(pagePaintings, loadPage)
    checkPage(pagePhotos, loadPage)
  }
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { body } = document
  if (
    body.classList.contains('ReactModal__Body--open') ||
    body.classList.contains('modal-open')
  ) {
    return false
  }
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.scrollTo(...(savedPosition || [0, 0]))
    // The problem is the page is scrolling before it navigates away
    // window.setTimeout(
    //   () => window.scrollTo(...(savedPosition || [0, 0])),
    //   transitionDelay,
    // )
    // window.scroll({
    //   left: savedPosition[0] || 0,
    //   top: savedPosition[1] || 0,
    //   behavior: 'smooth',
    // })
  }
  return false
}

export const onInitialClientRender = () => {
  window.___G_A_T_S_B_Y_INITIAL_RENDER_COMPLETE = true
}
