import Typography from 'typography'

const typography = new Typography({
  baseFontSize: '18px',
  baseLineHeight: 1.45,
  // scaleRatio: 2,
  headerFontFamily: ['Alegreya', 'serif'],
  bodyFontFamily: ['Alegreya Sans', 'sans-serif'],
  headerWeight: 'normal',
  bodyWeight: 'normal',
  boldWeight: 'bold',
  headerColor: '#232323',
  bodyColor: '#232323',
  // blockMarginBottom: 1,
  // includeNormalize: false,
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles()
}

export const { rhythm, scale } = typography

export default typography
