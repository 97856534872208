import React, { useState, type FC } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Logo from './Logo'
import { iconPlus, iconTwitter, iconInstagram, iconYoutube } from './Icons'
import illustrationNavbar from '../assets/illustrations/navbar.svg'

type WrapperProps = {
  $pathname: string
  $pageIsHome: boolean
  $pageIsResume: boolean
  $menuOpen: boolean
}

const Wrapper = styled.nav<WrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;

  .mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    transition: transform 700ms
        ${({ theme }) => theme.transitionTiming_velocity},
      background-color 700ms;

    ${({ $pageIsHome }) =>
      $pageIsHome &&
      css`
        transform: translateY(-100%);

        &.slide-enter-active,
        &.slide-enter-done {
          transform: translateY(0);
        }

        &.slide-exit-active,
        &.slide-exit-done {
          transform: translateY(-100%);
        }
      `}
  }

  .decoration {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: -100px;
    left: 0;
    z-index: -3;
    opacity: 0;
    transition: opacity 700ms ${({ theme }) => theme.transitionTiming_velocity};

    &.fade-enter-active,
    &.fade-enter-done {
      opacity: 1;
    }
  }

  .menu {
    @media (max-width: 750px) {
      position: absolute;
      top: 100%;
      right: 0;
      z-index: -2;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-top: 20px;
      transform: translateY(-200%);

      a {
        padding-right: 40px;
      }
    }

    &.menu-open-is-true {
      &.slide-enter-active,
      &.slide-enter-done {
        @media (max-width: 750px) {
          transform: translateY(0);
        }

        .menu-2 {
          transform: translateY(0);
        }
      }
    }

    &.menu-open-is-false:not(.slide-exit):not(.slide-exit-active) {
      @media (max-width: 750px) {
        display: none;
      }

      .menu-2 {
        display: none;
      }
    }
  }

  .menu,
  .menu-2 {
    transition: transform 700ms
        ${({ theme }) => theme.transitionTiming_velocity},
      background-color 700ms;
  }

  .menu-2 {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: -2;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    transform: translateY(-200%);
    @media (max-width: 750px) {
      flex-direction: column;
    }
  }

  .link-logo {
    display: flex;
    align-items: center;
    transition: filter 3s;

    &:hover {
      filter: hue-rotate(360deg);
    }

    svg {
      width: 100px;
      height: 39px;

      #logoGradientStop1,
      #logoGradientStop2 {
        transition: stop-color 1s;
      }

      ${({ $pageIsResume }) =>
        $pageIsResume &&
        css`
          stroke-width: 1px;
          stroke: #000;
        `}
    }
  }

  a {
    display: inline-block;
    text-align: center;
    @media (max-width: 750px) {
      text-align: right;
    }

    &.has-emoji {
      @media (hover: hover) {
        &:hover {
          .emoji {
            display: block;
            animation: animationWave 0.3s infinite;
          }
        }
      }

      span {
        position: relative;
      }
    }

    .emoji {
      position: absolute;
      left: -26px;
      display: none;
      transform-origin: bottom right;
    }

    span {
      display: inline-block;
    }
  }

  a,
  .button-more {
    padding: 12px 20px;
    font-size: 17px;
    border-radius: 1px;
  }

  .links-social {
    display: flex;
    padding-right: 10px;
    padding-left: 10px;
    @media (max-width: 750px) {
      padding-top: 5px;
      padding-left: 20px;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
    }
  }

  .button-more {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    width: 100px;
    background-color: transparent;
    border: none;
    overflow: hidden;
    position: relative;

    svg {
      width: 20px;
      height: 20px;
    }

    .plus {
      transform-origin: center;
      transition: transform 700ms
        ${({ theme }) => theme.transitionTiming_velocity};
      ${({ $menuOpen }) =>
        $menuOpen &&
        css`
          transform: rotateZ(90deg);
        `}
    }

    span {
      position: absolute;
      top: 50%;
      right: 20px;
      transition: transform 0.25s
        ${({ theme }) => theme.transitionTiming_velocity};
      transform: translateY(-50%);

      &.slide-enter-active,
      &.slide-exit {
        transform: translateY(-50%);
      }

      &.slide-enter,
      &.slide-exit-active {
        transform: translateY(100%);
      }
    }
  }

  .svg-decoration {
    display: block;
    position: absolute;
    top: calc(100% - 3px);
    left: 0;
    width: 100%;
    min-width: 1200px;
    height: auto;
    transition: fill 700ms;

    ${({ $pageIsResume }) =>
      $pageIsResume &&
      css`
        border-top: 1px solid #000;
      `}
  }

  ${({ theme }) => css`
    .menu-2,
    .mask {
      background-color: ${theme.color_dark};
    }

    .menu {
      @media (max-width: 750px) {
        background-color: ${theme.color_dark};
      }
    }

    .backdrop {
      background-image: linear-gradient(
        to bottom right,
        hsla(${theme.color_1_hsl}, 0.7),
        hsla(${theme.color_2_hsl}, 0.7)
      );
    }

    a,
    .button-more {
      color: ${theme.color_link};
      outline-color: ${theme.color_1};
    }

    a.active {
      color: ${theme.color_link_active};
    }

    #logoGradientStop1 {
      stop-color: ${theme.color_1};
    }

    #logoGradientStop2 {
      stop-color: ${theme.color_2};
    }

    .svg-decoration {
      fill: ${theme.color_dark};
    }
  `}
`

const isPartiallyActive = ({
  isPartiallyCurrent,
  className,
}: {
  className: string
  isPartiallyCurrent: boolean
}) => {
  return {
    className: `${className} underline-parent ${
      isPartiallyCurrent ? 'active' : ''
    }`,
  }
}

type NavbarProps = {
  pathname: string
  pageIsHome: boolean
  pageIsResume: boolean
}
const Navbar: FC<NavbarProps> = ({ pathname, pageIsHome, pageIsResume }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const closeMenu = () => setMenuOpen(false)

  const PartialNavLink = ({ className = '', ...props }: any) => (
    <Link
      getProps={({ isPartiallyCurrent }) =>
        isPartiallyActive({ isPartiallyCurrent, className })
      }
      onClick={closeMenu}
      {...props}
    />
  )

  return (
    <Wrapper
      role="navigation"
      $pathname={pathname}
      $pageIsHome={pageIsHome}
      $pageIsResume={pageIsResume}
      $menuOpen={menuOpen}
    >
      <CSSTransition in={menuOpen} timeout={700} classNames="slide">
        <div className="mask">
          <div className="decoration">
            <svg viewBox="0 0 2307.8517 38.066124" className="svg-decoration">
              <use xlinkHref={`${illustrationNavbar}#svgNavbar`} />
            </svg>
          </div>
        </div>
      </CSSTransition>
      <CSSTransition
        in={menuOpen}
        timeout={700}
        unmountOnExit
        classNames="fade"
      >
        <div onClick={closeMenu} className="backdrop" />
      </CSSTransition>
      <Link
        to="/#✨"
        className="link-logo"
        onClick={closeMenu}
        aria-label="Home"
      >
        {Logo}
      </Link>
      <CSSTransition in={menuOpen} timeout={700} classNames="slide">
        <div className={`menu menu-open-is-${menuOpen}`}>
          {/* <PartialNavLink to="/design/code">
            <span className="underline">Portfolio</span>
          </PartialNavLink> */}
          <PartialNavLink to="/paintings">
            <span className="underline">Paintings</span>
          </PartialNavLink>
          <PartialNavLink to="/photos">
            <span className="underline">Photos</span>
          </PartialNavLink>
          <PartialNavLink to="/notes">
            <span className="underline">Notes</span>
          </PartialNavLink>
          <div className="menu-2">
            <svg viewBox="0 0 2307.8517 38.066124" className="svg-decoration">
              <use xlinkHref={`${illustrationNavbar}#svgNavbar`} />
            </svg>
            <PartialNavLink to="/me">
              <span className="underline">Me</span>
            </PartialNavLink>
            <PartialNavLink to="/contact" className="has-emoji">
              <span className="underline">
                <div className="emoji">👋🏻</div>
                Say hi
              </span>
            </PartialNavLink>
            <div className="links-social">
              <a
                href="https://twitter.com/daniel_pintilei"
                target="_blank"
                rel="noopener"
              >
                {iconTwitter}
              </a>
              <a
                href="https://instagram.com/danielpintilei.art"
                target="_blank"
                rel="noopener"
              >
                {iconInstagram}
              </a>
              <a
                href="https://www.youtube.com/channel/UCChD4xdoX4hyMvvDpmg4nuQ"
                target="_blank"
                rel="noopener"
              >
                {iconYoutube}
              </a>
            </div>
          </div>
        </div>
      </CSSTransition>
      <button
        type="button"
        onClick={() => setMenuOpen(!menuOpen)}
        className="button-more"
        aria-haspopup="true"
        aria-expanded={menuOpen}
      >
        {iconPlus}
        <TransitionGroup>
          {menuOpen ? (
            <CSSTransition key="Less" timeout={250} classNames="slide">
              <span>Less</span>
            </CSSTransition>
          ) : (
            <CSSTransition key="More" timeout={250} classNames="slide">
              <span>More</span>
            </CSSTransition>
          )}
        </TransitionGroup>
      </button>
    </Wrapper>
  )
}

export default Navbar
