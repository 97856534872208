export const themeConstants = {
  transitionTiming_velocity: 'cubic-bezier(0.9, 0, 0.1, 1)',
  clipPath_button:
    'polygon(9% 27%, 42% 9%, 93% 31%, 88% 75%, 71% 92%, 24% 88%, 4% 61%)',
}

export const themeColors = {
  default: {
    color_1: '#61e786',
    color_1_h: 137,
    color_1_hsl: '137, 74%, 64%',
    color_2: '#9792e3',
    color_2_h: 244,
    color_2_hs: '244, 59%',
    color_2_hsl: '244, 59%, 73%',
    color_link: '#9792e3',
    color_link_active: '#61e786',
    color_dark: 'hsl(244, 15%, 18%)',
    color_dark_hsl: '244, 15%, 18%',
    color_footer_1_sl: '70%, 85%',
    color_footer_2_sl: '70%, 70%',
    color_footer_3_l: '65%',
  },
  paintings: {
    color_1: '#00ffff',
    color_1_h: 180,
    color_1_hsl: '180, 100%, 50%',
    color_2: '#ff4500',
    color_2_h: 16,
    color_2_hs: '16, 100%',
    color_2_hsl: '16, 100%, 50%',
    color_link: 'hsl(180, 75%, 70%)',
    color_link_active: 'hsl(16, 100%, 60%)',
    color_dark: 'hsl(180, 10%, 13%)',
    color_dark_hsl: '180, 10%, 13%',
    color_footer_1_sl: '70%, 85%',
    color_footer_2_sl: '70%, 70%',
    color_footer_3_l: '65%',
  },
  notes: {
    color_1: '#f09f70',
    color_1_h: 22,
    color_1_hsl: '22, 81%, 69%',
    color_2: '#e1ebc3',
    color_2_h: 75,
    color_2_hs: '75, 50%',
    color_2_hsl: '75, 50%, 84%',
    color_link: 'hsl(22, 85%, 72%)',
    color_link_active: 'hsl(75, 50%, 84%)',
    color_dark: 'hsl(75, 9%, 12%)',
    color_dark_hsl: '75, 9%, 12%',
    color_footer_1_sl: '70%, 85%',
    color_footer_2_sl: '70%, 70%',
    color_footer_3_l: '65%',
  },
  photos: {
    color_1: '#ffbb4d',
    color_1_h: 37,
    color_1_hsl: '37, 100%, 65%',
    color_2: '#3963ff',
    color_2_h: 227,
    color_2_hs: '227, 100%',
    color_2_hsl: '227, 100%, 61%',
    color_link: 'hsl(37, 100%, 75%)',
    color_link_active: 'hsl(227, 100%, 73%)',
    color_dark: 'hsl(34, 10%, 13%)',
    color_dark_hsl: '34, 10%, 13%',
    color_footer_1_sl: '100%, 89%',
    color_footer_2_sl: '100%, 76%',
    color_footer_3_l: '75%',
  },
  me: {
    color_1: '#ed9c40',
    color_1_h: 32,
    color_1_hsl: '32, 83%, 59%',
    color_2: '#75ac66',
    color_2_h: 107,
    color_2_hs: '107, 30%',
    color_2_hsl: '107, 30%, 54%',
    color_link: 'hsl(107, 40%, 60%)',
    color_link_active: 'hsl(32, 100%, 60%)',
    color_dark: 'hsl(107, 10%, 15%)',
    color_dark_hsl: '107, 10%, 15%',
    color_footer_1_sl: '75%, 85%',
    color_footer_2_sl: '75%, 70%',
    color_footer_3_l: '65%',
  },
  contact: {
    color_1: '#4169e1',
    color_1_h: 225,
    color_1_hsl: '225, 73%, 57%',
    color_2: '#ff69b4',
    color_2_h: 330,
    color_2_hs: '330, 100%',
    color_2_hsl: '330, 100%, 71%',
    color_link: 'hsl(225, 80%, 70%)',
    color_link_active: 'hsl(330, 100%, 71%)',
    color_dark: 'hsl(225, 10%, 15%)',
    color_dark_hsl: '225, 10%, 15%',
    color_footer_1_sl: '90%, 85%',
    color_footer_2_sl: '90%, 70%',
    color_footer_3_l: '70%',
  },
  // portfolio: {
  //   color_1: '#93f5ec',
  //   color_1_h: 174,
  //   color_1_hsl: '174, 83%, 77%',
  //   color_2: '#a77bf3',
  //   color_2_h: 244,
  //   color_2_hs: '244, 59%',
  //   color_2_hsl: '244, 59%, 73%',
  //   color_link: '#58a6ff',
  //   color_link_active: '#93f5ec',
  //   color_dark: 'hsl(215, 21%, 11%)',
  //   color_dark_hsl: '215, 21%, 11%',
  //   color_footer_1_sl: '70%, 85%',
  //   color_footer_2_sl: '70%, 70%',
  //   color_footer_3_l: '65%',
  // },
  resume: {
    color_1: '#fff',
    color_1_h: 0,
    color_1_hsl: '0, 0%, 100%',
    color_2: '#fff',
    color_2_h: 0,
    color_2_hs: '0, 0%',
    color_2_hsl: '0, 0, 100%',
    color_link: '#000',
    color_link_active: 'blue',
    color_dark: 'hsl(0, 0%, 100%)',
    color_dark_hsl: '0, 0%, 100%',
    color_footer_1_sl: '0%, 100%',
    color_footer_2_sl: '0%, 100%',
    color_footer_3_l: '100%',
  },
}

export type Theme = typeof themeColors.default & typeof themeConstants
