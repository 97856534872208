import React from 'react'
import styled from 'styled-components'

const Svg = styled.svg.attrs({
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: 'none',
  stroke: 'currentColor',
  strokeWidth: '2',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
})``

export const iconPlus = (
  <Svg>
    <line className="plus" x1="12" y1="5" x2="12" y2="19" />
    <line x1="5" y1="12" x2="19" y2="12" />
  </Svg>
)

export const iconClose = (
  <Svg>
    <line x1="18" y1="6" x2="6" y2="18" />
    <line x1="6" y1="6" x2="18" y2="18" />
  </Svg>
)

export const iconNext = (
  <Svg>
    <line x1="19" y1="12" x2="5" y2="12" />
    <polyline points="12 19 5 12 12 5" />
  </Svg>
)

export const iconPrev = (
  <Svg>
    <line x1="5" y1="12" x2="19" y2="12" />
    <polyline points="12 5 19 12 12 19" />
  </Svg>
)

export const iconShare = (
  <Svg>
    <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8" />
    <polyline points="16 6 12 2 8 6" />
    <line x1="12" y1="2" x2="12" y2="15" />
  </Svg>
)

export const iconMail = (
  <Svg>
    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
    <polyline points="22,6 12,13 2,6" />
  </Svg>
)

export const iconBuy = (
  <Svg>
    <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
    <line x1="3" y1="6" x2="21" y2="6"></line>
    <path d="M16 10a4 4 0 0 1-8 0"></path>
  </Svg>
)

const SvgSocial = styled(Svg)`
  stroke: ${({ theme }) => theme.color_link};
  stroke-width: 1.5px;
  &:hover .hover {
    stroke-dashoffset: 0;
  }
  .hover {
    stroke: ${({ theme }) => theme.color_link_active};
    stroke-dasharray: 170;
    stroke-dashoffset: 170;
    transition: stroke-dashoffset 1s;
  }
`

const pathTwitter =
  'm 2.9067775,4.6097172 2.1426459,2.2068905 2.6548669,1.3751829 3.6463177,0.6719643 0.09608,-2.4308787 1.823159,-2.1432246 3.198921,-0.3518988 1.758914,1.1836065 1.375761,-0.255821 1.47126,-0.6395526 L 20.178751,5.4095921 19.027557,6.4328762 20.339074,6.240721 21.682424,5.697246 20.658561,7.008763 19.347044,7.9359695 19.1873,10.718747 18.195849,13.853422 16.404523,16.380379 14.29371,18.235371 11.255109,19.57872 8.0561888,20.186441 5.1773339,19.80271 1.5310159,18.363282 3.9300617,18.235371 6.201197,17.499741 7.6087914,16.540123 5.2415786,15.868737 3.5781631,13.82159 5.5292326,13.789757 3.6424077,12.893804 2.3308907,11.038813 2.1711473,9.6636298 4.0903839,10.175272 2.6509564,8.6079339 2.1711473,6.0809776 Z'
export const iconTwitter = (
  <SvgSocial>
    <path d={pathTwitter} />
    <path className="hover" d={pathTwitter} />
  </SvgSocial>
)

const pathInstagram1 =
  'M 4.4227734,4.0473191 6.5785176,3.0674953 10.23657,2.8713989 l 6.336283,0.1638524 2.514376,0.7508254 1.894502,2.8743252 0.196095,5.1603621 -0.294143,5.323555 -1.273969,2.645327 -2.416328,1.241067 -4.89912,0.09805 L 7.5583414,21.030713 4.9452585,20.377278 3.4107056,18.287338 2.8224164,12.669637 3.0514148,5.9740647 Z'
const pathInstagram2 =
  'm 9.5508912,8.5220014 2.8414238,-0.8166295 2.122841,0.489583 1.469407,2.2866941 0.06515,3.298762 -1.991892,1.893844 -3.135568,0.327046 -2.514377,-1.731307 -0.8166295,-2.743375 z'
const pathInstagram3 =
  'M 17.631852,6.8003682 17.568286,7.0600247 17.358338,6.8514334 Z'
export const iconInstagram = (
  <SvgSocial>
    <g>
      <path d={pathInstagram1} />
      <path d={pathInstagram2} />
      <path d={pathInstagram3} />
    </g>
    <g className="hover">
      <path d={pathInstagram1} />
      <path d={pathInstagram2} />
      <path d={pathInstagram3} />
    </g>
  </SvgSocial>
)

const pathYoutube1 =
  'M 2.073076,5.3038881 3.9745829,4.5783016 10.556364,4.305934 l 6.310859,0.062157 3.565473,0.4264857 1.53857,0.8658506 0.755417,1.8298537 0.337088,3.863872 -0.177177,3.751003 -0.649696,2.822664 -1.348531,1.215644 -4.765833,0.521529 -7.6596527,0.02907 L 2.8497984,19.06605 1.366093,17.637127 0.936515,12.111867 1.3373106,6.7984303 Z'
const pathYoutube2 =
  'M 9.8706853,8.8887399 15.592077,12.094359 9.8686678,15.325561 Z'
export const iconYoutube = (
  <SvgSocial>
    <g>
      <path d={pathYoutube1} />
      <path d={pathYoutube2} />
    </g>
    <g className="hover">
      <path d={pathYoutube1} />
      <path d={pathYoutube2} />
    </g>
  </SvgSocial>
)
