import React, { type FC } from 'react'
import styled, { css } from 'styled-components'
import { IllustrationFooter } from './Illustrations'
import { Theme } from '../utils/theme'

type WrapperProps = {
  $pageIsResume: boolean
  theme: Theme
}

const Wrapper = styled.footer<WrapperProps>`
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  overflow: hidden;

  svg {
    display: block;
    width: 100%;
    min-width: 600px;
    height: auto;
  }

  path {
    transition: fill 700ms;

    ${({ $pageIsResume }) =>
      $pageIsResume &&
      css`
        fill: none !important;
        stroke-width: 1px;
        stroke: #000;
      `}
  }

  ${({ theme }) => css`
    .layer1-footer {
      fill: hsl(${theme.color_1_h}, ${theme.color_footer_1_sl});
    }

    .layer2-footer {
      fill: hsl(${theme.color_1_h}, ${theme.color_footer_2_sl});
    }

    .layer3-footer {
      fill: hsl(${theme.color_2_hs}, ${theme.color_footer_3_l});
    }

    .layer4-footer {
      fill: ${theme.color_dark};
    }

    .layer5-footer {
      fill: hsl(${theme.color_2_h}, 5%, 25%);
    }
  `}
`

type FooterProps = {
  pageIsResume: boolean
}

const Footer: FC<FooterProps> = ({ pageIsResume }) => (
  <Wrapper $pageIsResume={pageIsResume}>{IllustrationFooter}</Wrapper>
)

export default Footer
