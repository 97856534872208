import React, { useEffect, type FC, type PropsWithChildren } from 'react'
import { createGlobalStyle, ThemeProvider, css } from 'styled-components'
import 'typeface-alegreya'
import 'typeface-alegreya-sans'
import Transition from './Transition'
import Navbar from './Navbar'
import Footer from './Footer'
import { svgBrushstroke } from './Illustrations'
import { rhythm, scale } from '../utils/typography'
import { themeConstants, themeColors } from '../utils/theme'

const GlobalStyle = createGlobalStyle`
  html {
    &:not(:has(#___gatsby > div > .layout-wrapper.page-is-resume)) {
      background-color: #fffafa;
    }
    
    &.modal-open {
      overflow-y: hidden;

      .next-prev-links-wrapper {
        padding-bottom: 20px;
        background-color: transparent;
        box-shadow: none;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .button {
    &:hover {
      transform: scale(1.1);
    }
  }

  button,
  .button {
    transition: transform 0.1s;

    &:active {
      transform: scale(0.9);
    }
  }

  a,
  button {
    outline-width: 2px;
  }

  #___gatsby > div > .layout-wrapper {
    min-height: 100vh;
    margin-bottom: calc(100vw / 4);
    padding-top: 100px;
    padding-bottom: 15px;
    background-color: #fffafa;
    box-shadow: 0 3px 7px hsla(${({ theme }) => theme.color_dark_hsl}, 0.05);
    
    @media (max-width: 600px) {
      margin-bottom: 150px;
    }

    &.page-is-home {
      margin-bottom: 0;
      background: linear-gradient(to bottom, #fffafa, hsla(0, 0%, 100%, 0.8) 55%, transparent);
      box-shadow: none;
    }
    
    &.page-is-resume {
      background-color: #fff;
      border-bottom: 1px solid #000;
    }
    
    .header-page {
      margin-bottom: ${rhythm(0.5)};
      font-size: ${scale(1.2).fontSize};
      font-style: italic;
      text-align: center;
    }

    .info-page {
      max-width: 450px;
      margin: 0 auto ${rhythm(2)};
      font-size: ${scale(-0.1).fontSize};
      line-height: ${scale(-0.1).lineHeight};
      text-align: center;
      color: hsl(0, 0%, 50%);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes animationWave {
    0%,
    100% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(20deg);
    }
    75% {
      transform: rotate(-15deg);
    }
  }

  @keyframes slideFadeUp {
    from {
      opacity: 0;
      transform: translateY(100%);
    }
    to {
      opacity: 1;
      transform:  translateY(0);
    }
  }

  @keyframes slideRightLink {
    from {
      transform: scaleX(0);
      opacity: 0;
    }
    to {
      transform: scaleX(1);
      opacity: 1;
    }
  }

  .underline {
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 0 2px;
    transition: background-size 0.4s ${({ theme }) =>
      theme.transitionTiming_velocity};
  }

  .underline-parent {
    &:hover,
    &.active {
      &.underline,
      .underline {
        background-size: 100% 2px;
      }
    }
  }

  .brushstroke-background {
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0.6em;
      bottom: 0;
      left: 0.15em;
      right: 0.15em;
      clip-path: url(#brushstroke);
      mix-blend-mode: multiply;
      transition:
        transform 0.4s ${({ theme }) => theme.transitionTiming_velocity},
        opacity 0.4s ${({ theme }) => theme.transitionTiming_velocity};
      transform-origin: 90%;
      animation: slideRightLink 1s ${({ theme }) =>
        theme.transitionTiming_velocity};
    }
  }

  .brushstroke-background-parent:hover {
    &.brushstroke-background::after,
    .brushstroke-background::after {
      transform: scaleX(0);
      opacity: 0;
    }
  }

  ${({ theme }) => css`
    ::selection {
      color: #232323;
      background-color: ${theme.color_1};
    }

    a,
    button {
      outline-color: ${theme.color_dark};
    }

    .underline {
      background-image: linear-gradient(
        to right,
        ${theme.color_1},
        ${theme.color_2},
        transparent
      );
    }

    .brushstroke-background::after {
      background: linear-gradient(
        to right,
        hsla(${theme.color_1_hsl}, 0.5),
        hsla(${theme.color_2_hsl}, 0.5)
      );
    }
  `}
`

let consoleLogHello = false

type LayoutProps = PropsWithChildren<{
  location: { pathname: string }
}>

const Layout: FC<LayoutProps> = ({ location: { pathname }, children }) => {
  const pageIsHome = pathname === '/'

  const pageIsResume = pathname.split('/')[1] === 'resume'

  const pageIsModal =
    /\/paintings\/20/.test(pathname) || /\/photos\/20/.test(pathname)

  const basePath = pathname.split('/')[1]

  // @ts-ignore
  const currentColorTheme = themeColors[basePath] || themeColors.default

  const currentTheme = { ...themeConstants, ...currentColorTheme }

  useEffect(() => {
    const metaThemeColor = document.querySelector('meta[name=theme-color]')

    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', currentTheme.color_dark)
    }
  })

  useEffect(() => {
    if (!consoleLogHello) {
      console.log(
        `%c\n👋🏻 Hello there cyber traveler!%c\n\nThis website is designed and developed by me.\n\n%cArt%c can also be created with %ccode%c!\n\nGo on, feel free to break anything. If you have any questions or you just want to say hi, you can write me at hey@danielpintilei.art\n`,
        `font-size: 20px; color: aqua`,
        `font-size: 17px;`,
        `padding: 30px 26px; font-size: 17px; color: white; text-shadow: 2px 2px 2px black; background: conic-gradient(red, yellow, lime, aqua, blue, magenta, red); border-radius: 50%;`,
        `font-size: 17px`,
        `padding: 2px 6px; font-size: 17px; color: lightgreen; background-color: green`,
        `font-size: 17px`
      )
      consoleLogHello = true
    }
  }, [])

  return (
    <>
      <ThemeProvider theme={currentTheme}>
        <>
          <GlobalStyle />
          <Navbar
            pathname={pathname}
            pageIsHome={pageIsHome}
            pageIsResume={pageIsResume}
          />
          <div
            className={`layout-wrapper ${pageIsHome ? 'page-is-home' : ''} ${
              pageIsResume ? 'page-is-resume' : ''
            }`}
          >
            {pageIsModal ? (
              children
            ) : (
              <Transition pathname={pathname}>{children}</Transition>
            )}
          </div>
          <Footer pageIsResume={pageIsResume} />
        </>
      </ThemeProvider>
      {svgBrushstroke}
    </>
  )
}

export default Layout
