import React, { type FC, type PropsWithChildren } from 'react'
import {
  TransitionGroup,
  Transition as ReactTransition,
} from 'react-transition-group'
import styled, { css } from 'styled-components'

const timeout = 200

type TransitionStylesProps = {
  $status: string
}
const TransitionStyles = styled.div<TransitionStylesProps>`
  ${({ $status }) => {
    switch ($status) {
      case 'entering':
        return css`
          position: absolute;
          opacity: 0;
        `
      case 'entered':
        return css`
          transition: opacity ${timeout}ms
            ${({ theme }) => theme.transitionTiming_velocity};
          opacity: 1;
        `
      case 'exiting':
        return css`
          transition: all ${timeout}ms
            ${({ theme }) => theme.transitionTiming_velocity};
          opacity: 0;
        `
    }
  }}
`

type TransitionProps = PropsWithChildren<{
  pathname: string
}>
const Transition: FC<TransitionProps> = ({ pathname, children }) => {
  return (
    <TransitionGroup>
      <ReactTransition
        key={pathname}
        timeout={{
          enter: timeout,
          exit: timeout,
        }}
      >
        {(status) => (
          <TransitionStyles $status={status}>{children}</TransitionStyles>
        )}
      </ReactTransition>
    </TransitionGroup>
  )
}

export default Transition
